body
{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif!important;

}
[type=search]:focus
{
  border-color: #85d685;
  box-shadow: 0 0 0 0.25rem rgb(10 173 10 / 25%);
}
.btn-outline-gray-400 {
  color: #c1c7c6;
  border-color: #c1c7c6;
}
.btn-outline-gray-400:hover
{
  color: #000;
  background: #c1c7c6;
  border-color: #c1c7c6;
}
.btn-outline-gray-400:focus
{
  box-shadow: rgb(193, 199, 198);
}
.btn-outline-gray-400:active
{
  color: #000;
  background: #c1c7c6;
  border-color: #c1c7c6;
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}
.btn-outline-gray-400:disabled
{
  color: #c1c7c6;
  background: transparent;
  border-color: #c1c7c6;
}
.icon-heart
{
  color: rgb(122, 122, 122);
}
.icon-heart:hover
{
  color: rgb(10, 173, 10);
}
[type=search]
{
  width: 300px!important;
}
.btn-success
{
  background-color: #0aad0a;
  border: #0aad0a;
}
.btn-success:hover
{
  background-color: #099309;
}
.btn-success:active
{
  background-color: #088a08;
}
.dropdown-content-1
{
  width: 200px;
  background-color: white;
}
.dropdown-content-1 a
{
  width: 200px;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}
.dropdown-content-2
{
  width: 1140px;
  background-color: white;
}
.dropdown-content-2 a
{
  width: 300px;
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}
.dropdown-content-2 h4
{
  width: 300px;
  background-color: white;
  color: #088a08;
  padding-top: 5px;
  padding-bottom: 5px;
}
.carousel-item {
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.fs
{
  font-size: 1.75rem!important;
  
}
.bg-img-1
{
  background-image: url(pic/ad-banner-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img-2
{
  background-image: url(pic/ad-banner-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img-3
{
  background-image: url(pic/ad-banner-3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.rounded-3
{
  border-radius: 5px;
}
.card-product
{
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: all;
  cursor: pointer;
}
.card-product:hover {
  border: 1px solid #0aad0a;
  box-shadow: 0 5px 12px rgb(0 0 0 / 12%);
}
@media (min-width: 992px){
  .bg-green{
    background-color: #0aad0a;
  }
  .text-lg-white{
    color: white!important;
  }
}
@media (min-width: 992px){
  .nav-link
  {
    color: hsla(0,0%,100%,.85)!important;
  }
  .nav-link:hover
  {
    color: #fff!important;
  }
}
.carousel-control-prev
{
  border-radius: 50%;
  background: #f0f3f2;
  border: none;
  border-radius: 50px;
  color: #001e2b;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 32px;
  left: 80%;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  width: 32px;
}
.carousel-control-next
{
  border-radius: 50%;
  background: #f0f3f2;
  border: none;
  border-radius: 50px;
  color: #001e2b;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 32px;
  left: 90%;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  width: 32px;
}
.bg-light
{
  background-color: #F0F3F2!important;
}
li
{
  margin-top: 5px;
}
a li:hover
{
  color: #099309;
}
.fs-2
{
  font-size: 14px;
}
.fs-3
{
  font-size: 13px;
}
.carousel-item
{
  height: auto!important;
}


/* MEDIA QUEREY */

@media screen and (max-width: 464px) {
  .appstore-btn{
    width: 100px;
  }
  .googleplay-btn{
    width: 100px;
  }
}

.abc:hover
{
  position: relative!important;
}

.card
{
  position: relative!important;
}

.card-details
{
  position: relative;
  left: 0;
  top:30%;
  width: 100%;
  text-align: center;
  height: 100%;
  display: none;
}
.card:hover .card-details{
  bottom: 0;
  position: absolute;
  display: block;
}
.card:hover .card-body{
  bottom: 0;
  position: relative!important;
}
.card:hover .card-body{
  bottom: 0;
  position: absolute;
}
.btn-light:hover
{
  background-color: #0aad0a;
  border: #0aad0a;
  color: white;
}
.card:hover
{
  width: 100%!important;
  height: 100%!important;
}
.dropdown-item:active
{
    color: #16181b!important;
    text-decoration: none!important;
    background-color: #e9ecef!important; 
}