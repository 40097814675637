.whats_icon {
  position: fixed;
  bottom: 30px;
  /* right: 20px; */
  left: 93%;
  z-index: 10;
}

.whats_icon:hover a i{
  color: rgb(79, 206, 93);
}

.whats_icon i {
  font-size: 80px;
  color: rgb(79, 206, 93);
  /* transform: rotateZ(-96deg); */
  /* background: linear-gradient(
    32deg,
    rgba(79, 206, 93, 0.8547794117647058) 33%,
    rgba(255, 255, 255, 0.3113620448179272) 68%,
    rgba(67, 90, 100, 0.6867121848739496) 92%
  ); */

  /* color: #4FCE5D; */
}
@media (max-width: 430px) {
  .whats_icon i{
    font-size: 48px;
   }
   .whats_icon{
    left: 83%;
   }
}

@media screen and (max-width: 390px) {
 .whats_icon i{
  font-size: 48px;
 }
 .whats_icon{
  left: 85% !important;
 }
}



@media (max-width: 820px) {
  .whats_icon i{
    font-size: 60px;
   }
   .whats_icon{
    left: 88%;
   }
 }

@media (max-width: 768px) {
  .whats_icon i{
    font-size: 60px;
   }
   .whats_icon{
    left: 90%;
   }
 }

 @media screen and (max-width: 280px) {
  .whats_icon i{
   font-size: 48px;
  }
  .whats_icon {
    left: 83% !important;
}

html{
  overflow-x:hidden !important;
}
 }
 

 @media screen and (max-width: 430px) {
  .whats_icon {
    left: 86%;
}
}