/* Navbar.css */
/* body{
    overflow: hidden;
} */
.container-fluid{
  margin: 0;
    padding: 0;
}
.header_2 .main_header_2{
  display: flex;
  gap: 200px;
}
.header_2 .navbarr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 20px; */
    padding-left: 19px;
    padding-right: 19px;
    background-color: #fff !important;
   
    /* background-color: #118611 !important; */
    /* background: rgb(255,255,255);
    background: linear-gradient(94deg, rgba(182,229,139,1) 0%, rgba(182,229,139,1) 26%) !important; */
    
    border-bottom: 10px solid #2b4413;
  }
  
  .header_2  .alert__Sec {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    font-size: 12px;
  }
  
  .header_2  .alert__Sec .alert___para {
    display: flex;
    align-items: center;
  }
  
  .header_2  .alert__Sec .alert_Sub_Sec button {
    background-color: #00abc6;
    border-color: #00abc6;
    color: #000;
  }
  

  .header_2 .main_headerr_2{
    display: flex;
    gap: 248px;
  }
  .header_2  .navbarr .links {
    display: flex;
    align-items: center;
    gap: 23px;
    white-space: nowrap;
    /* visibility: hidden; */
  }
  
  .header_2  .navbar__a {
    font-size: 20px;
  }
  
  .header_2  .navbarr .btns a {
    padding: 6px 10px !important;
    font-size: 13px;
  }
  
  .header_2  .navbarr .btns a:hover {
    color: #000;
  }
  
  .header_2  .navbarr .img__navbar {
    width: auto;
    height: 69px;
  }
  
  .header_2  .navbarr .nav-link {
    font-size: 15px;
  }
  
  .header_2  .navbarr .navbar__a {
    font-size: 17px;
    color: #000;
    padding: 10px 20px;
  }
  
  .header_2  .navbarr .navbar__a:hover {
    /* font-size: 22px; */
    color: #0b1802;
    transition: 0.6s;
   padding: 10px 20px;
   background-color: #0cc935 !important; 
   color: #fff !important;
  }
  
  .header_2  .div_Above_navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top: 1px solid lightgray; */
  }
  
  .header_2  .div_Above_navbar .alert_sec {
    display: flex;
    gap: 15px;
    padding: 20px;
  }
  
  .header_2  .div_Above_navbar .a__above_div {
    color: darkgrey;
    font-size: 12px;
  }
  
  .header_2  .div_Above_navbar .a__above_div:hover,
  .header_2  .div_Above_navbar .a__above_div:active,
  .header_2  .div_Above_navbar .a__above_div:focus {
    color: darkgrey;
    transition: 0.6s;
  }
  
  .header_2  .div_Above_navbar .above__navbar__icons {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-right: 15px;
  }
  
 
   .fa-facebook-f{
    color: #00abc6 !important;
  }
 
  
  .header_2  .div_Above_navbar .above__navbar__icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 1285px) {
    .header_2 .main_headerr_2{
gap: 200px;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .header_2  .navbarr .navbar__a {
      font-size: 20px !important;
    }
  }
  
  @media screen and (max-width: 1234px) {
    .header_2 .main_headerrrr_2{
      gap: 170px !important;
    }
    .header_2 .main_headerr_2{
      gap: 30px;
    }
  }
  @media screen and (max-width: 1123px) {
    .header_2 .main_headerrrr_2{
      gap: 100px !important;
    }
    .header_2 .main_headerr_2{
      gap: 30px;
    }
  }

  @media screen and (max-width: 1047px) {
    .header_2 .main_headerrrr_2{
      gap: 70px !important;
    }
    .header_2 .main_headerr_2{
      gap: 20px;
    }
  }

  @media screen and (max-width: 1015px) {
    .header_2 .main_headerrrr_2{
      gap: 20px !important;
    }
    .header_2 .main_headerr_2{
      gap: 0px;
    }
  }
  @media screen and (max-width: 991px) {
    .header_2  .navbar-toggler {
      border: none !important;
      box-shadow: none;
      display: none;
    }
    .header_2 .main_headerr_2{
      flex-direction: column;
    }
    .header_2 .main_headerr_2{
      gap: 0 !important;
    }
    .offcanvas__{
      visibility: visible !important;
    }
    .header_2 .main_headerrrr_2{
      display: flex !important;
    }
   
  
    .header_2  .navbar-toggler-icon {
      filter: invert(1);
    }
  
    .header_2    .navbarr .links {
      flex-direction: column !important;
  
    }
  
    .header_2  .banner_Home .text {
      padding: 0 !important;
    }
  
    .header_2 .navbarr .btns {
      margin: 10px 0;
      display: flex !important;
      justify-content: center !important;
    }
    .header_2 .main_headerrrr_2{
      /* flex-direction: column; */
      display: block !important; 
    }
    .header_2 .btns{
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 600px) {
    .header_2  .btnnn {
      padding: 4px 9px !important;
      font-size: 10px;
    }
  }
  
  @media screen and (max-width: 550px) {
    .header_2   .alert__Sec {
      display: none;
    }
  }
  
  @media screen and (max-width: 500px) {
  
    .header_2  .btnnn {
      padding: 4px 14px !important;
      font-size: 12px;
    }
  }
  
  .header_2  .navbar-toggler {
    background-color: #000 !important;
  }
  
  .header_2  .btns a .fa-facebook-f {
    color: #0D89EF !important;
    font-size: 25px !important;
  }
  
  .header_2  .btns{
    display: flex !important;
    align-items: center;
    gap: 9px;
    white-space: nowrap;
  }
  .header_2  .btns a .fa-instagram{
    color: #FB0188 !important;
    font-size: 25px !important;
  }
  .header_2  .btns:active{
    border: none;
  }
  .fa-twitter{
    font-size: 25px;
    color: #00abc6;
  }
  .fa-youtube{
    font-size: 25px;
    color: red;
  }
 
  .header_2  .btns .a_num{
  color: #000 !important;
  font-size: 20px;
  }
  .header_2  .contact_me{
    color: #000;
  }
  .header_2  .contact_me i{
    color: #0D89EF !important;
  }
  .header_2 .main_headerrrr_2{
    display: flex !important;
    gap: 177px;
    /* ustify-content: ; */
    align-items: baseline;
    width: 1306px !important;
    justify-content: space-between;
  }

  ul,
li,
button,
h1,
h2,
h3,
h4,
h5,
h6,
input
a,
p{

font-family: 'Roboto', sans-serif !important; 
}

@media screen and (max-width: 990px) {
  .header_2 .navbar-toggler {
    margin-left: 700px;
   
  }
  .header_2 .navvv_barrrr{
    margin-top: -50px !important;
  }
  .contact_me_{
    visibility: visible !important;
    margin: auto;

  }
}

@media screen and (max-width: 912px) {
  .header_2 .navbar-toggler {
    margin-left: 540px;
  }
}
@media screen and (max-width: 820px) {
  .header_2 .navbar-toggler {
    margin-left: 540px;
  }
  
}



@media screen and (max-width: 651px) {
  .header_2 .main_headerrrr_2{
    width: 377px !important;
  }
}
@media screen and (max-width: 540px) {
  .header_2 .navbar-toggler {
    margin-left: 300px;
  }
  .header_2 .main_headerrrr_2 {
    width: 485px !important;
}
}
@media screen and (max-width: 414px) {
  .header_2 .navbar-toggler {
    margin-left: 170px;
  }
  .header_2 .main_headerrrr_2 {
    width: 335px !important;
}
}

@media screen and (max-width: 375px) {
  .header_2 .navbar-toggler {
    margin-left: 140px;
  }
  .whats_icon2{
    right: 35px !important;
    top: 53px;
  }
  .header_2 .navbar-toggler{
    margin-left: 202px;
  }
  .header_2 .main_headerrrr_2 {
    width: 305px !important;
}

  .whats_icon i{
  font-size: 48px;
 }
 .whats_icon{
  left: 87%;
 }
}

@media screen and (max-width: 430px) {
  .header_2 .main_headerrrr_2 {
    width: 366px !important;
}
}
@media screen and (max-width: 390px) {
  .header_2 .main_headerrrr_2 {
    width: 347px !important;
}
}
@media screen and (max-width: 281px) {
  .header_2 .navvv_barrrr{
    margin-top: 0px !important;
  }

}

@media screen and (max-width: 280px) {
  .header_2 .navbar-toggler {
    margin-left: 50px;
  }
  .header_2 .main_headerrrr_2 {
    width: 225px !important;
}
.whats_icon2 {
  right: 10px !important;
  top: 50px;
}
}
.header_2 .container_header{
  padding: 0px 0 0px 0 !important;
}


.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}

.login-button{
  background: none;
    flex-direction: column;
    color: #000;
    align-items: center;
}
.login-button:hover{
  background-color: #fff;
}
.login-button .login_icons{
  padding: 7px 9px;
  border: 1px solid #fff;
  background: #000;
  color: #fff;
  border-radius: 100%;
}
.nav_baar_toggle {
  background-color: #fff !important;
}
.offcanvas__{
  visibility: hidden;
}




.offcanvas-body{
display: flex;
flex-direction: column;
gap: 29px;
}

.contact_me_{
  visibility: hidden;
}