.NaturalOils a:hover {
    color: rgb(6, 124, 6) !important;
}
.NaturalOils {
    overflow: hidden !important;
}
.NaturalOils .heading_product h1 {
    display: block;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 6px;
}

.NaturalOils .main_product {
    background-color: #FFFF;
    box-shadow: -3px 2px 12px 7px;
    padding-left: 28px !important;
    padding-right: 28px !important;
}

.NaturalOils .main_product .rating_product ul {
    display: flex;
    gap: 5px;
    align-items: center;
}

.NaturalOils .main_product .rating_product ul .fa-star {
    color: #DDDDDD;
}

.NaturalOils .main_product .heading_product {
    border-bottom: 2px solid #DDDDDD;
    padding-bottom: 30px;
}

.NaturalOils .main_images_producy .contents .price {
    margin: 0;
    font-size: 22px;
    line-height: inherit;
}

.NaturalOils .main_images_producy .contents .discription {
    margin-bottom: 0;
    display: inline-block;
    color: #B399A6;
    /* width:500px; */
    font-size: 14px;
}
.NaturalOils .main_images_producy .contents .availablitiy{
    margin: 0;
    font-size: 18px;
    line-height: inherit;
}
.NaturalOils .main_images_producy .contents .availablitiy span{
    color: rgb(222, 28, 28);
}

.NaturalOils .main_images_producy .contents .content_prices{
    border-bottom: 2px solid #DDDDDD;
    padding-bottom: 20px;
}
.NaturalOils .main_images_producy{
    display: flex;
    justify-content: space-around;
    padding-bottom: 80px;
}
.NaturalOils .main_images_producy .contents .cotogory h3{
    font-size: 14px;
    font-weight: 600;
}

.NaturalOils .main_images_producy .contents .cotogory h3 span{
    font-size: 13px;
    font-weight: 400 !important;
}
.NaturalOils  .main_product .nav-link ,.NaturalOils  .main_product .nav-link:hover{
    background: none !important;
    color: #888 !important;
    border: 1px solid #888 !important; 
    border-radius: 0px !important;
}

.NaturalOils .main_product .benifits , .Usage ,.Ingredients ,.main_reviews h2{
    font-size: 20px;
    
}
.NaturalOils .main_product .ul_benifits{
    padding: 10px;
    
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;

  }
  
  td, th {
    border: 1px solid #bbb5b5;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  .NaturalOils .main_product button{
    /* padding: 7px 50px; */
    border-radius: 5px ;
    border: none;
    
  }
  .NaturalOils .main_product_1{
    padding: 20px;
  }
  .NaturalOils .main_images_producy .contents {
    width: 50% !important;
  }
  @media (max-width: 1000px) {
    .NaturalOils .main_images_producy {
      flex-direction: column;
    }
    .NaturalOils .main_images_producy .image  img{
        width: 100% !important;
    }
    .NaturalOils .main_images_producy .contents{
        width: 100% !important;
        padding-top: 10px ;
    }
}


