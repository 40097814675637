/* Footer.css */


  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .logo-animation {
    animation: zoomInOut 2s infinite alternate; /* Zoom in and out animation */
  }
  
  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2); /* Adjust the scale factor for the desired zoom effect */
    }
  }
  
  .social-icons a {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
  }
  
  /* Media Query for Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .logo-animation {
      animation: none; /* Remove the animation on smaller screens */
    }
  
    .social-icons {
      margin-top: 20px;
      justify-content: center;
      
    }
  }
  
  /* HerbalFooter.css */

.herbal-footer {
    /* background-color: #004236; */
    background-color: #fff;
    color: #000;
    padding: 20px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    padding: 0 20px;
  }
  

  
  .info {
    flex: 1;
    margin: 20px;
  }
  
  .info h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .social-icons a {
    color: #000;
    text-decoration: none;
    margin-right: 10px;
    font-size: 24px;
  }
  
  /* Media Query for Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .info {
      margin: 20px 0;
      text-align: center;
    }
  }
  .info a{
    color: #000;
  }
  .info a:hover{
    color: #000;
  }
  .fa-facebook-f{
    font-size: 25px;
    color: #00abc6;
  }
  .fa-twitter{
    font-size: 25px;
    color: #00abc6;
  }
  .fa-youtube{
    font-size: 25px;
    color: red;
  }
 .fa-instagram{
    color: #FB0188 !important;
    font-size: 25px !important;
  }
  iframe{
    width: 440px;
    height: 300px;
  }
  .address_p{
    white-space: nowrap;
  }
  footer{
    border-top: 8px solid #000;
    overflow: hidden;
  }

/* iframe.css */
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* 4:3 aspect ratio */
  overflow: hidden;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Define styles for different screen sizes */
@media screen and (max-width: 768px) {
  .iframe-container {
    padding-bottom: 56.25%; /* 16:9 aspect ratio for smaller screens */
  }
}

/* Add more media queries for other screen sizes as needed */

