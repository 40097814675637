* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sidebar_ .main-container {
  display: flex;
}
.sidebar_ main {
  padding: 10px;
    width: 100%;
    height: 100% !important
}

 .title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar_  .sidebar {
  background: rgb(2, 23, 1);
  color: white;
  /* height: 100vh; */
  overflow-y: auto;
}

.sidebar_  .top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.sidebar_  .logo {
  font-size: 18px;
  line-height: 0;
}
.sidebar_  .bars {
  width: 30px;
}
.sidebar_  .hide {
  display: none;
}

.sidebar_  .search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.sidebar_ .search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.sidebar_ .routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.sidebar_ .link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar_  .link:hover {
  border-right: 4px solid white;
  background: rgb(3, 77, 18);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.sidebar_  .active {
  border-right: 4px solid white;
  background: rgb(84, 20, 3);
}
.sidbarmenu_ .link_text {
  white-space: nowrap;
  font-size: 15px;
}

.sidbarmenu_  .menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.sidbarmenu_ .menu_item {
  display: flex;
  gap: 10px;
}
.sidbarmenu_ .menu_container {
  display: flex;
  flex-direction: column;
}
.sidbarmenu_ .menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}
