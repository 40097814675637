.banner_Home {
  height: 100vh;
  /* background: rgb(3,8,1);
  background: linear-gradient(94deg, #C8E360 40%, rgba(11, 130, 11, 0.64) 100%); */
  /* background: rgb(3,8,1);
  background: linear-gradient(94deg, #b7e30859 0%, rgba(11,130,11,1) 100%); */
 /* background: rgb(3,8,1);
 background: linear-gradient(94deg, #afca47 0%, rgba(11,130,11,1) 100%); */
 /* background: rgb(3,8,1);
 background: linear-gradient(94deg, #a0c6b3 0%, rgba(11,130,11,1) 100%); */
 /* background: rgb(3,8,1);
 background: linear-gradient(94deg, #fff 0%, rgba(11,130,11,1) 100%); */
 /* background: rgb(3,8,1);
 background: linear-gradient(94deg, rgba(11,130,11,1) 0%, #fff 100%); */


 background: rgb(3,8,1);
 background: linear-gradient(94deg, rgb(7, 100, 7) 30%, #fff 100%);
 /* background: linear-gradient(94deg, #90EE90 100%, #fff 100%); */
 /* background: linear-gradient(94deg, #90EE90 0%, #fff 100%); */
 /* background: linear-gradient(94deg, #51C878 0%, #fff 100%); */
 /* background: linear-gradient(94deg, #51C878 100%, #fff 100%); */
  overflow: hidden;
  position: relative;
 }

  .banner_Home::before {
    content: "";
    position: absolute;
    /* top: 0; */
    inset: 0;
    /* background-color: #49C3D6; */
    /* background: rgb(8, 1, 17); */
    /* background: linear-gradient(
      180deg,
      rgba(8, 1, 17, 1) 0%,
      rgba(14, 33, 46, 1) 17%,
      rgba(12, 25, 39, 1) 82%,
      rgba(8, 1, 17, 1) 100%
    ); */
  }
  .banner_Home::after {
    content: "";
    position: absolute;
    /* top: 50%;
    left: 10%; */
    inset: 0;
    /* background: rgba(8, 1, 17, 0.089); */
    /* background: linear-gradient(
      90deg,
      rgba(8, 1, 17, 1) 0%,
      rgba(12, 29, 41, 0.171) 7%,
      rgba(8, 1, 17, 1) 130%
      ); */
      z-index: 1;
  }


.banner_Home .container-fluid {
  height: 100%;
}
.banner_Home .text {
  display: flex;
  /* padding: 150px 0 0 0; */
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 0 50px;
  height: 100%;
/* }
.banner_Home .text .left{ */
}
.banner_Home .text .left{
  width: 40%;
  position: relative;
  z-index: 2;   
}
.banner_Home .text .right {
  width: 50%;
}

.banner_Home .text .right {
  display: flex;
  gap: 4%;
}
.banner_Home .text .right .one {
  display: flex;
  height: calc(60px * 18);
  flex-direction: column;
  width: 100%;
  animation: scroll_one 30s linear infinite;
  
}
.banner_Home .text .right .one .imgg {
  width: 100%;
  margin: 10px 0;
  /* overflow: hidden; */
}
.banner_Home .text .right .one .imgg img {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  /* height: 250px; */
  object-fit: cover;
}
.banner_Home .text .right .two {
  display: flex;
  height: calc(60px * 18);
  flex-direction: column;
  width: 100%;
  animation: scroll_two 30s linear infinite;
}
.banner_Home .text .right .two .imgg {
  width: 100%;
  margin: 10px 0;
}
.banner_Home .text .right .two .imgg img {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
}

@keyframes scroll_one {
  0% {  
     transform: translateX(20px);
  }
  100% {
    transform: translateY(calc(-60px * 80));
  }
}
@keyframes scroll_two {
  0% {
    transform: translateY(calc(-60px * 80));
  }
  100% {
    transform: translateY(0);
  }
}

.banner_Home .text .left .content {
  display: inline;
}
.banner_Home .text .left p {
  color: #fff !important;
  margin: 20px 0;
  line-height: 1.5;
}
.banner_Home .button_2 a{
  padding: 10px 40px !important;
  color: #fff !important;
  background-color: #000 !important;
}
.banner_Home .button_1 {
  display: flex;
  gap: 10px;
}
.banner_Home .button_1 button{
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  white-space: nowrap;
  background-color: #000 !important;
}
.banner_Home .text .left h1 {
  font-weight: 800;
  color: #fff;
  display: inline;
  line-height: 1;
  line-height: 60px;
}

.banner_Home .text .left h1 span {
  /* background: linear-gradient(90deg, #8998D8 4.64%, #B8A0DC 11.05%, #D68A7A 18.07%, #F97339 25.85%, #F48F1F 33.94%); */
    background: linear-gradient(90deg, #f3f3f3 4.64%, #fdfdfd 11.05%, #ffffff 18.07%, #ffffff 25.85%, #f5f5f5 33.94%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  font-size: 47px;
  border-bottom: 5px solid #000;
}

.banner_Home .text .btnn {
  display: flex;
  gap: 10px;
  align-items: center;
}

.banner_Home .text .btnn a.abo {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}
.banner_Home .text .btnn a.abo i.firt {
  display: none;
}

.banner_Home .text .btnn a.abo:hover {
  margin-left: 10px;
}
.banner_Home .text .btnn a.abo:hover i.sec {
  display: none;
}
.banner_Home .text .btnn a.abo:hover i.firt {
  display: block;
}

/* .banner_Home .slidingimages{
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    z-index: 1;
    height: 500px;
    transform: rotate(-20deg);
} */
ul,
li,
button,
h1,
h2,
h3,
h4,
h5,
h6,
input
a,
p{
  font-family: 'Marcellus SC', serif;
font-family: 'Merriweather', serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif; 
}
@media (max-width: 912px) {
  .whats_icon2{
    right: 70px !important;
   }
}
@media (max-width: 850px) {
  .banner_Home::after{
     /* background: rgba(8, 1, 17, 0.089); */
    background: linear-gradient(
      90deg,
      rgba(8, 1, 17, 1) 0%,
      rgba(12, 29, 41, 0.171) 7%,
      rgba(8, 1, 17, 1) 130%
      );
  }
  .whats_icon2{
    right: 70px !important;
   }
}
@media (max-width: 430px) {
  .whats_icon2{
    right: 30px !important;
    top: 50px;
   }
   .banner_Home .text .right .one{
    visibility: hidden !important;
   }
   .banner_Home .text .left p{
    font-size: 10px;
    width: 50%;
   }
   .banner_Home .text .left h1{
    font-size: 12px !important;
   }
}
@media (max-width: 430px) {
  .banner_Home .text .left h1{
   
    padding-left: 10px;
    padding-right: 10px; 
  }
  .banner_Home .text .left p{
    padding-left: 10px;
    padding-right: 10px;
  }
 
  .banner_Home .text .left{
    left: 52% !important
  }
  .address_p{
    font-size: 13px !important;
  }
  .banner_Home .button_1 button{
    width: 100% !important;
  }
  .banner_Home .button_1{
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 390px) {
  .banner_Home .button_1 button{
    padding: 10px 10px;
  }
  .banner_Home .text .left{
    padding-right: 16px !important;
  }
  
}
@media (max-width: 414px) {
  .banner_Home .text .left h1{
    font-size: 10px !important;
    padding-left: 10px;
    padding-right: 10px; 
  }
  .banner_Home .text .left p{
    padding-left: 10px;
    padding-right: 10px;
  }
  .banner_Home .button_1{
    padding-left: 10px;
    padding-right: 10px; 
    display: flex;
    flex-direction: column;
  }
  .banner_Home .text .left{
    left: 53% !important;
  }
  
  
}
@media (max-width: 375px) {
  .banner_Home .text .left h1{
   font-size: 10px !important;
    padding-left: 10px;
    padding-right: 10px; 
  }
  .banner_Home .text .left p{
    padding-left: 10px;
    padding-right: 10px;
  }
  .banner_Home .button_1{
    padding-left: 10px;
    padding-right: 10px; 
  }
  
}
@media (max-width: 375px) {
 .banner_Home .button_1 button{
  padding: 7px 10px;
 }
 .banner_Home .button_2 button{
  padding: 5px 10px;
 }
 .whats_icon2{
  right: 35px !important;
 }
 .banner_Home .button_1 button{
  padding: 7px 6px !important; 
 }
 
}
@media (max-width: 360px) {
  .address_p{
    font-size: 10px !important;
  }
  .whats_icon2{
    right: 20px !important;
    top: 50px;
   }
 }
 

 @media (max-width: 768px) {
  
  .whats_icon2{
    right: 10px !important;
    top: 50px;
   }
 }

 @media (max-width: 820px) {
  
  .whats_icon2{
    right: 10px !important;
    top: 50px;
   }
 }
 
 @media (max-width: 280px) {
  
  .whats_icon2 {
    right: 10px !important;
    top: 50px;
}
 }