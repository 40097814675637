.main_navbar{
    gap: 20px;

}
.navbar_nav{
    display: flex;
    flex-direction: column;
}
.main_navvv {
    background-color: #fff !important;
}

.back_navbar{
    background-color: #055416;
}
i{
  font-size: 13px;
}
.nav-item a{
    display: flex;
    align-items: center !important;
    gap: 5px;

}
.fa-cart-shopping{
    /* color: rgb(128, 9, 9); */
    color: #000;
}
.main_navvv {
    align-items: center;
}
.main_navvv Button {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 50px;
    width: 50px;
    background: none;
    border: none;
    margin-left: 10px;
    
}
.main_navvv Button:hover ,.main_navvv Button:visited ,.main_navvv Button:active ,.main_navvv Button:focus-visible ,.main_navvv Button:focus{
    background: none !important;  
    border: none !important; 
    box-shadow: none !important; 
}
.main_navvv Button .div ,.main_navvv Button .div1 ,.main_navvv Button .div2{
    width: 40px ;
    height: 3px !important;
    border: 2px solid #000;
    margin-left: -7px;
    
}
.offcanvas-body ul {
   display: flex;
   flex-direction: column;
   gap: 40px;
}
.offcanvas-body ul li a{
    display: flex;
    align-items: center;
    gap: 10px;
}
.offcanvas-body ul li a i{
    font-size: 20px;
}
.offcanvas-body ul li a:hover{
    color: rgb(4, 74, 4);
}

.offcanvas-title img{
    width: auto;
    height: 80px;
}
.back_navbar .nav-item:visited{
    background-color: blue !important;
    color: #fff !important;
    cursor: pointer;
}
.back_navbar .nav-item:active{
    background-color: #0cc935 !important; 
    color: #fff !important;
    cursor: pointer;

}

.back_navbar .nav-item:focus{
    background-color: aqua !important;
    color: #fff !important;
    
}
.back_navbar .nav-item:hover{
    /* background-color: rgb(96, 10, 10); */
     background-color:#0cc935;
    cursor: pointer;
    color: #fff;
    

}

.back_navbar .nav-item {
    background-color: #fff;
    color: #000 !important;
    border-radius: 8px;
    width: 150px;
    display: flex;
    height: 44px;
    cursor: pointer;

    
    justify-content: center;
}
.back_navbar .nav-item .nav-link{
    color: #000 !important;
}
.back_navbar .navbar-nav{
    gap: 20px;
}
.back_navbar .nav-link {
    margin-top: 5px;
    padding: 10px 50px;
}
.back_navbar .nav-link{
    padding: 10px 20px;
    
}
.back_navbar .sidenavbar_butt{
   display: none;
}
@media (max-width: 1000px) {
    .back_navbar .sidenavbar_butt {
      display: flex !important;
    }
    .back_navbar .navbar-nav{
        display: none;
    }
}

.whats_icon2 {
    position: fixed;
    bottom: 30px;
    right: 40px;

    z-index: 10;
    top:50px;
  }
  .counter{
    font-size: 10px;
    position: absolute;
    /* background-color:yellow; */
    /* padding-left: 2px; */
    /* padding-right: 2px; */
   
    border-radius: 100%;
    width: 16px;
    border-radius: 100%;
    text-align: center;
  }



.Headerrrssss{
  position: sticky;
    top: 0;
    z-index: 999;
}