body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none !important;
  font-family: 'Marcellus SC', serif;
font-family: 'Merriweather', serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif;
}
ul,
li,
button,
h1,
h2,
h3,
h4,
h5,
h6,
input
a,
p{
  font-family: 'Marcellus SC', serif;
font-family: 'Merriweather', serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif; 
}



ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
