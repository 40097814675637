/* NewBanner.css */
.title {
    position: relative;
  }
  
  .title img {
    width: 100%;
    height: auto;
  }
  
  .subtitle {
    position: absolute;
    top: 80%;
    bottom: 50%;
    left: 22%;
    transform: translate(-50%, -50%);

    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .subtitle .get_an_app  , .shop_now{
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px 30px;
    border: none;
    font-size: 16px;
    color: #000;
    cursor: pointer;
  }
  
  .subtitle .get_an_app:hover ,.shop_now:hover {
    background-color: green;
    transition: 0.5s;
  }
  @media (max-width: 865px) {
    .subtitle .get_an_app{
      padding: 3px 6px !important;
      font-size: 14px;
    }
    .shop_now {
      padding: 3px 40px!important;
      font-size: 14px;
  }
  }


  @media (max-width: 659px) {
    .subtitle .get_an_app {
      padding: 3px 6px !important;
      font-size: 12px;
    }
    .subtitle{
      top:76% !important;
    }
  }
  @media (max-width: 501px) {
    .subtitle .get_an_app {
      padding: 3px 6px !important;
      font-size: 12px;
    }
    .subtitle {
      top: 73% !important;
  }
  .shop_now {
    padding: 3px 27px!important;
    font-size: 12px;
}
  .subtitle{
    left: 15%;
  }
  }


.shop_now{
  padding: 10px 60px ;
}

@media (max-width: 280px) {
  .subtitle .get_an_app {
    padding: 2px 6px !important;
    font-size: 12px;
    font-size: 9px;
    white-space: nowrap;
}
.shop_now {
  padding: 2px 27px!important;
  font-size: 8px;
  /* white-space-collapse: preserve-breaks; */
}

.subtitle {
  top: 62% !important;
}
.subtitle {
  left: 7%;
}
}