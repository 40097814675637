/* styles.css */

/* Form container */
.form-container {
  max-width: 400px;
  margin: 0 auto;
  /* padding: 20px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Form inputs */
.form-container label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.form-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Form buttons */
.form-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 768px) {
  .form-container {
    max-width: 100%;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

select {
  width: 50% !important;
  padding: 10px !important;
  height: 46px !important;
  margin-top: -6px !important;
}

button {
  background-color: #078214;
  color: #fff;
}

.first {
  align-items: center;
}

/* Adjust margins and padding for responsive layout */
.container {
  padding: 20px;
}

.pt-2 {
  padding-top: 20px;
}

.form_form_1 {
  width: 50%;
  border: 1px solid #000;
  box-shadow: 1px 5px 11px 7px #fff;
  padding-bottom: 20px;
}

/* Style form labels and inputs */
label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"] {
  width: 100%;
  /* padding: 2px; */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
label{
  font-size: 14px;
}
/* Style the select box */
select {
  padding: 0;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 9px !important;
}

/* Style the submit button */
button[type="submit"] {
  background-color: #078214;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .col-md-12 {
    width: 100%;
  }

  .col-lg-6 {
    width: 100%;
  }
}

.gradient-text {

  text-align: center;
  color: #085704;
  font-size: 20px;
  padding: 20px;
}



.gradient_img {
  width: auto;
  height: 100px;

}

.form_logo {
  display: flex;
  justify-content: center;
}

body {
  margin: 0;
  padding: 0;
}


.form_form_1 {
  position: absolute;
  z-index: 999 !important;
  top: 24% !important;
  background: #fff !important;
  width: 30% !important;
  
  border-radius: 10px;
}

.select_1 {
  padding-bottom: 5px !important;
  width: 100% !important;
  height: 34px !important;
  padding-top: 4px !important;
  
}
input{
  font-size: 14px;
}
.signinform_1 {
  background-image: url("../../../assets/counting.jpg");
  /* background-color: #1D78CF; */
  height: 620px;
  background-size: cover;
  background-repeat: no-repeat;

}


@media (max-width: 767px) {
  .form_form_1 {
    width: 50% !important;
  }
}
@media (max-width: 534px) {
  .form_form_1 {
    width: 70% !important;
  }
}
@media (max-width: 358px) {
  .form_form_1   {
    width: 90% !important;
  }
}
