/* styles.css */

/* Form container */
.product_form .form-container {
    max-width: 400px;
    margin: 0 auto;
    /* padding: 20px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Form inputs */
  .product_form .form-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .product_form .form-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Form buttons */
  .product_form .form-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .product_form  .form-container {
      max-width: 100%;
    }
  }
  .product_form form{
    display: flex;
    flex-direction: column;
    gap: 5px;
}  
.product_form select{
    width: 50% !important;
    padding: 10px !important;
    height:46px !important;
    margin-top:-6px !important;
}

.product_form button{
    background-color: #078214;
    color: #fff;
}

.product_form .first{
  align-items: center;
}
/* Adjust margins and padding for responsive layout */
 .product_form .container {
    padding: 20px;
  }
  
  .product_form  .pt-2 {
    padding-top: 20px;
  }
  .product1_form .typeselect{
    width: 100% !important;
    height: 33px !important;
    padding-bottom: 3px !important;
    padding-top: 3px !important;
  }
  .product_form .product1_form{
    width: 50%  ;
    border: 1px solid #000;
    box-shadow: 1px 5px 11px 7px;
    padding-bottom:20px ;
  }
  /* Style form labels and inputs */
  .product_form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .product_form input[type="text"] {
    width: 100%;
    padding: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Style the select box */
  .product_form select {
    padding: 0;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 9px !important;
  }
  
  /* Style the submit button */
  .product_form button[type="submit"] {
    background-color: #078214;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .product_form  .col-md-12 {
      width: 100%;
    }
  
    .product_form  .col-lg-6 {
      width: 100%;
    }
  }
  
  .product_form .gradient-text {
   
    text-align: center;
    color: #085704;
    font-size: 20px;
    padding: 20px;
  }
  


  .product_form .gradient_img{
    width: auto;
    height: 100px;
   
  }
  .product_form .form_logo{
    display: flex;
    justify-content: center;
  }
body{
  margin: 0;
  padding: 0;
}
.product_form {
  background: url("../../../assets/counting.jpg") no-repeat center/cover;
  /* background-color: #1D78CF; */ /* Uncomment this line if you want a fallback background color */
  height: 1250px;
}
.product_form .select_2 {
  padding-bottom: 5px !important;
  width: 100% !important;
  height: 40px !important;
  padding-top: 4px !important;
  font-size: 14px;
  
}
.product_form .first_2{
  justify-content: space-between;
}
  .product1_form{
    position:absolute;
    z-index: 999 !important;
    top: 10%;
    background: #fff !important;
    width: 50% !important;
    border-radius: 10px;
  }
  .product_form  .select_3{
    padding: 10px 10px 10px 200px !important;
  }
  .product_form .select_5{
    width: 150% !important;
  }
  .product_form .select_6{
   width: 40% !important;

  }
  .product_form  .netweight{
    visibility: hidden;
  }
  .product_form  .productname{
    width: 210% !important;
  }

  textarea:focus-visible {
    outline: none;
}

input:focus-visible,
select:focus-visible {
    outline: none;
}


@media (max-width: 865px) {
  .product1_form {
    width: 60% !important;
  }
}
@media (max-width: 767px) {
  .product1_form {
    width: 70% !important;
  }
}
@media (max-width: 619px) {
  .product1_form {
    width: 80% !important;
  }
}
@media (max-width: 534px) {
  .product1_form {
    width: 90% !important;
  }
}
@media (max-width: 477px) {
  .product1_form   {
    width: 100% !important;
  }
}