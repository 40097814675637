*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* background-color: #080111; */
  overflow-x: hidden;
  /* cursor: url(""); */
}

img {
  width: 100%;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #000;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.scrolling {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  gap: 10px;
  top: 50%;
  transform: translate(0, -50%);
  right: 20px;
  cursor: pointer;
  z-index: 99;
  /* opacity: 1; */
}
.scrolling > span {
  writing-mode: vertical-lr;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #fff;
}

.scrolling .scroll_line {
  width: 1px;
  height: 60px;
  background-color: #fff;
  color: inherit;
  transform: translate("0px,2.32");
  position: relative;
}

.scrolling .scroll_line .scroll_bar {
  position: absolute;
  width: 8px;
  height: 8px;
  top: -4px;
  left: -4px;
  background-color: #49c3d6;
  border-radius: 4px;
}
/* .scrolling */

/* Bttnn */

.btnnn {
  padding: 10px 30px;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 500;
  background-color: #034903;
  border: 1px solid transparent;
  transition: 0.3s all;
  color: #fff;
}

.btnnn:hover {
  background-color: transparent !important;
  border: 1px solid #034903 !important;
  color: #000 !important;
}

.btnnn_inver {
  /* padding: 10px 30px; */
  border-radius: 30px;
  letter-spacing: 1.5px;
  font-weight: 500;
  background-color: #49c3d6;
  border: 1px solid transparent !important;
  transition: 0.3s all;
  color: #fff;
}

.btnnn_inver:hover {
  background-color: transparent !important;
  border: 1px solid #49c3d6 !important;
  color: #49c3d6 !important;
}

.whats_icon {
  position: fixed;
  bottom: 30px;
  /* right: 20px; */
  left: 20px;
  z-index: 10;
}

.whats_icon:hover a i{
  color: rgb(79, 206, 93);
}

.whats_icon i {
  font-size: 80px;
  color: rgb(79, 206, 93);
  /* transform: rotateZ(-96deg); */
  /* background: linear-gradient(
    32deg,
    rgba(79, 206, 93, 0.8547794117647058) 33%,
    rgba(255, 255, 255, 0.3113620448179272) 68%,
    rgba(67, 90, 100, 0.6867121848739496) 92%
  ); */

  /* color: #4FCE5D; */
}





.social_icons{
  position: fixed;
  top: 50%;
  left: 20px;
  z-index: 10;
}

.social_icons ul{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.social_icons ul li a{
  color: #a9a9a9;
  font-size: 30px;
}


.social_icons ul li:first-child i{
  color: #1877F2;
}
.social_icons ul li:last-child i{
  color: #E4405F;
}


/* @media screen and (max-width: 500px) {
  .social_icons{
    display: none;
  }
} */